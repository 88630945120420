<template>
  <div id="topic">
    <div class="main">
      <div class="btnList">
        <ul @click="showInfo()">
          <li @click="num = 0">
            <img v-show="num != 0" src="../../img/上课/1.png" alt="" />
            <img v-show="num == 0" src="../../img/上课/2.png" alt="" />
            <span :class="{ active: num == 0 }">详情</span>
          </li>
          <li @click="num = 1">
            <img v-show="num != 1" src="../../img/上课/6.png" alt="" />
            <img v-show="num == 1" src="../../img/上课/5.png" alt="" />
            <span :class="{ active: num == 1 }">解析</span>
          </li>
          <li @click="num = 2">
            <img v-show="num != 2" src="../../img/上课/3.png" alt="" />
            <img v-show="num == 2" src="../../img/上课/4.png" alt="" />
            <span
              :class="{ active: num == 2 }"
              style="width: 0.38rem; flex-wrap: wrap; display: inline-block"
              @click="questionQrcode()"
              >扫码答题</span
            >
          </li>
        </ul>
        <div class="goTop" @click="toTop()">
          <img src="../../img/上课/7.png" alt="" title="回到顶部" />
        </div>
      </div>
      <div class="topicList">
        <div class="topicCode" v-show="num == 2">
          <div class="qrcodebox">
            <p class="right-btn">扫码答题</p>
            <div class="qrcodes" ref="qrcodeContainer1"></div>
          </div>
        </div>
        <ul class="topiclists" v-show="num != 2">
          <li v-for="(item, index) in topic" :key="index">
            <div class="top">
              <div class="title">
                <b>{{ index + 1 }}</b
                >. <span v-show="item.type == 0">选择题</span
                ><span v-show="item.type != 0">判断题</span>

                <div class="titletopic" v-html="item.question"></div>
              </div>
              <ul>
                <li>
                  <div>A</div>
                  <span v-html="item.a"> </span>
                </li>
                <li>
                  <div>B</div>
                  <span v-html="item.b"> </span>
                </li>
                <li>
                  <div>C</div>
                  <span v-html="item.c"> </span>
                </li>
                <li>
                  <div>D</div>
                  <span v-html="item.d"></span>
                </li>
              </ul>
            </div>
            <div class="line" v-show="isShow"></div>
            <div class="bottom" v-show="isShow">
              <div class="title">
                <div class="txt">
                  正确答案：<span v-html="item.answer"></span>
                </div>
                <div
                  class="text"
                  @click="
                    dialogVisible = true;
                    getQuestionInfo(item.id);
                  "
                >
                  答题统计
                </div>
              </div>
              <div class="info">解析：<span v-html="item.analysis"></span></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <el-button type="text" @click="dialogVisible = true"
      >点击打开 Dialog</el-button
    > -->
    <el-dialog title="答题统计" :visible.sync="dialogVisible" width="30%">
      <div class="dia-box">
        <ul>
          <li
            @click="
              dianum = 1;
              firstbtn();
            "
          >
            <b :class="{ li_b: dianum == 1 }">正确率</b>
          </li>
          <span></span>
          <li @click="dianum = 2">
            <b :class="{ li_b: dianum == 2 }">选项分布</b>
          </li>
          <span></span>
          <li @click="dianum = 3">
            <b :class="{ li_b: dianum == 3 }">答题记录</b>
          </li>
        </ul>
        <div class="dia-info">
          <div
            v-show="dianum == 1"
            class="firstech"
            style="width: 5.12rem; height: 5.12rem"
          ></div>
          <div
            v-show="dianum == 2"
            class="secondech"
            style="
              width: 5.52rem;
              height: 5.52rem;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div class="secongbox">
              <ul id="second">
                <li v-for="count1 in 4" :key="count1">
                  <div class="chose chose1">
                    <strong>50%</strong>
                    <strong>A选项</strong>
                  </div>
                  <ul class="second second1">
                    <li v-for="count in 10" :key="count">
                      <img
                        src="../../img/板块图标 - 副本_slices/人员_people 拷贝 4@2x.png"
                        style="width: 0.39rem"
                        alt=""
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div v-show="dianum == 3" class="third">
            <ul class="t_title">
              <li
                @click="
                  bdnum = 1;
                  aBtn();
                "
              >
                <b :class="{ b_active: bdnum == 1 }">A</b>
              </li>
              <span></span>
              <li
                @click="
                  bdnum = 2;
                  bBtn();
                "
              >
                <b :class="{ b_active: bdnum == 2 }">B</b>
              </li>
              <span></span>
              <li
                @click="
                  bdnum = 3;
                  cBtn();
                "
              >
                <b :class="{ b_active: bdnum == 3 }">C</b>
              </li>
              <span></span>
              <li
                @click="
                  bdnum = 4;
                  dBtn();
                "
              >
                <b :class="{ b_active: bdnum == 4 }">D</b>
              </li>
            </ul>
            <!-- <table>
              <tr>
                <td><span>张三</span> <span>铁道供电技术A1901班</span></td>
                <td><span>李四</span> <span>铁道供电技术A1901班</span></td>
              </tr>
              <tr>
                <td><span></span> <span></span></td>
                <td><span></span> <span></span></td>
              </tr>
              <tr>
                <td><span></span> <span></span></td>
                <td><span></span> <span></span></td>
              </tr>
              <tr>
                <td><span></span> <span></span></td>
                <td><span></span> <span></span></td>
              </tr>
              <tr>
                <td><span></span> <span></span></td>
                <td><span></span> <span></span></td>
              </tr>
              <tr>
                <td><span></span> <span></span></td>
                <td><span></span> <span></span></td>
              </tr>
            </table> -->
            <ul class="t_info">
              <li v-for="(item, index) in third" :key="index">
                <p>{{ item.user.name }}</p>
                <p>{{ item.class_info.class_name }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as util from "@/unit/network";
import * as echarts from "echarts";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      num: 0,
      isShow: false,
      dialogVisible: false,
      dianum: 1,
      bdnum: 1,
      topicLists: 1,
      topic: "",
      codeImgUrl: "",
      first: "",
      third: "",
    };
  },
  mounted() {
    this.getinfo();
    this.makeQRCode();
  },
  updated() {
    // this.firstbtn();
  },
  methods: {
    getinfo() {
      util.post(
        "/questionList",
        { catalog_file_id: this.$route.query.dataid },
        (res) => {
          this.topic = res.data;
          console.log(res.data);
        }
      );
    },
    showInfo() {
      if (this.num == 1) {
        this.isShow = true;
      } else if (this.num == 2) {
        this.questionQrcode();
        this.isShow = false;
      } else {
        this.isShow = false;
      }
    },
    firstbtn() {
      const t = this;
      console.log(this.first);
      setTimeout(() => {
        t.getFirst();
      }, 0);
    },
    getFirst() {
      var err = this.first.err * 100;
      var suc = this.first.suc * 100;
      let myFirst = echarts.init(document.querySelector(".firstech"));
      let option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "正确率",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              // show: false,
              // position: "center",
              normal: {
                show: true,
                position: "inner",
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: err, name: "答案错误" },
              { value: suc, name: "答案正确" },
            ],
          },
        ],
      };
      myFirst.setOption(option);
    },
    // secondbtn() {
    //   const t = this;
    //   setTimeout(() => {
    //     t.getSecond();
    //   }, 0);
    // },
    // getSecond() {
    //   let a = this.second.a;
    //   let b = this.second.b;
    //   let c = this.second.c;
    //   let d = this.second.d;
    //   let mySecond = echarts.init(document.querySelector(".secondech"));
    //   let option = {
    //     // title: {
    //     //   text: "某站点用户访问来源",
    //     //   subtext: "纯属虚构",
    //     //   left: "center",
    //     // },
    //     tooltip: {
    //       trigger: "item",
    //     },
    //     legend: {
    //       orient: "vertical",
    //       left: "left",
    //     },
    //     series: [
    //       {
    //         name: "选项分布（人）",
    //         type: "pie",
    //         radius: ["40%", "70%"],
    //         data: [
    //           { value: a, name: "A" },
    //           { value: b, name: "D" },
    //           { value: c, name: "C" },
    //           { value: d, name: "B" },
    //         ],
    //         emphasis: {
    //           itemStyle: {
    //             shadowBlur: 10,
    //             shadowOffsetX: 0,
    //             shadowColor: "rgba(0, 0, 0, 0.5)",
    //           },
    //         },
    //       },
    //     ],
    //   };
    //   mySecond.setOption(option);
    // },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            300;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    getQuestionInfo(id) {
      this.question_id = id;
      this.questionFirst();
      // this.questionSecond();
      this.aBtn();
    },
    questionFirst() {
      util.post(
        "/tongji_zql",
        {
          question_id: this.question_id,
          catalog_file_id: this.$route.query.dataid,
        },
        (res) => {
          // console.log(res);
          this.first = res.data;
          this.firstbtn();
        }
      );
    },
    questionSecond() {
      util.post(
        "/tongji_xxfb",
        {
          question_id: this.question_id,
          catalog_file_id: this.$route.query.dataid,
        },
        (res) => {
          console.log(res, "222");
          this.second = res.data;
          // this.secondbtn();
        }
      );
    },
    aBtn() {
      util.post(
        "/tongji_dtjl",
        {
          select: "a",
          question_id: this.question_id,
          catalog_file_id: this.$route.query.dataid,
        },
        (res) => {
          console.log(res, "222");
          this.third = res.data;
          // this.secondbtn();
        }
      );
    },
    bBtn() {
      util.post(
        "/tongji_dtjl",
        {
          select: "b",
          question_id: this.question_id,
          catalog_file_id: this.$route.query.dataid,
        },
        (res) => {
          console.log(res, "222");
          this.third = res.data;
          // this.secondbtn();
        }
      );
    },
    cBtn() {
      util.post(
        "/tongji_dtjl",
        {
          select: "c",
          question_id: this.question_id,
          catalog_file_id: this.$route.query.dataid,
        },
        (res) => {
          console.log(res, "222");
          this.third = res.data;
          // this.secondbtn();
        }
      );
    },
    dBtn() {
      util.post(
        "/tongji_dtjl",
        {
          select: "d",
          question_id: this.question_id,
          catalog_file_id: this.$route.query.dataid,
        },
        (res) => {
          console.log(res, "222");
          this.third = res.data;
          // this.secondbtn();
        }
      );
    },
    // questionThird(i) {
    //   util.post(
    //     "/tongji_dtjl",
    //     {
    //       select: i,
    //       question_id: this.question_id,
    //       catalog_file_id: this.$route.query.dataid,
    //     },
    //     (res) => {
    //       console.log(res, "222");
    //       this.third = res.data;
    //       // this.secondbtn();
    //     }
    //   );
    // },
    questionQrcode() {
      util.post(
        "/question_qrcode",
        {
          catalog_file_id: this.$route.query.dataid,
          class_id: localStorage.getItem("class_id"),
        },
        (res) => {
          console.log(res);
        }
      );
    },
    makeQRCode() {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrcodeContainer1, {
          text:
            "http://zy.yunlearning.com:8084/#/pages/topic/topic/?dataid=" +
            this.$route.query.dataid +
            "&ss=2" +
            "&class_id=" +
            localStorage.getItem("class_id") +
            "&cid=" +
            localStorage.getItem("cid") +
            "&csid=" +
            this.$route.query.csid,
          width: 578,
          height: 578,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
  },
  // watch: {
  //   num: {
  //     aa() {
  //       if (this.num == 1) {
  //         this.isShow = true;
  //       } else {
  //         this.isShow = false;
  //       }
  //     },
  //   },
  // },
};
</script>
<style lang="less" scoped>
#topic::-webkit-scrollbar {
  width: 0;
}
#topic {
  height: calc(100% - 11px);
  overflow: auto;
  .main {
    width: 1260px;
    height: 100%;
    margin: 11px auto;
    font-size: 20px;
    .btnList {
      position: fixed;
      right: 300px;
      bottom: 69px;
      width: 64px;
      text-align: center;
      ul {
        height: 302px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        li {
          color: #b7b7b7;
          font-size: 16px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            margin-bottom: 2px;
          }
          .active {
            color: #1c5eff;
          }
        }
      }
      .goTop {
        width: 64px;
        height: 46px;
        line-height: 46px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(171, 171, 171, 0.17);
        border-radius: 5px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
    .topicCode {
      width: 1260px;
      height: 100%;
      background-color: #fff;
    }
    .topiclists {
      margin-right: 40px;
      > li {
        background-color: #fff;
        padding: 48px 131px 56px 74px;

        margin-bottom: 5px;
        .top {
          .title {
            font-weight: 400;
            display: flex;
            color: #2c2c2c;
            align-items: flex-start;
            line-height: 23px;
            b {
              font-weight: 400;
            }
            .titletopic {
              display: inline-block;
              /deep/img {
                width: 100px !important;
                vertical-align: top;
                margin: 0 2px;
              }
              /deep/p {
                width: 930px;
                word-wrap: break-word;
                img {
                  display: block;
                  margin: 0 auto;
                }
              }
            }

            span {
              display: inline-block;
              width: 56px;
              height: 22px;
              line-height: 22px;
              background: #b3c9ff75;
              border-radius: 2px;
              font-size: 14px;
              font-weight: 400;
              color: #2769e1;
              text-align: center;
            }
          }
          ul {
            // margin-bottom: 61px;
            li {
              display: flex;
              // align-items: center;
              margin-top: 23px;
              div {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                width: 28px;
                height: 28px;
                border: 1px solid #cdcdcd;
                border-radius: 50%;
              }
              /deep/p {
                width: 680px;
                // flex-wrap: wrap;
                word-wrap: break-word;
                img {
                  display: block;
                  margin: 0 auto;
                }
              }
              /deep/img {
                width: 100px !important;
                vertical-align: top;
                margin: 0 2px;
              }
            }
          }
        }
        .line {
          margin-top: 61px;
          margin-bottom: 34px;
          height: 1px;
          background-color: #f3f3f3;
        }
        .bottom {
          //   margin-top: 34px;
          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;
            .text {
              padding: 9px 18px;
              background-color: #1c5eff;
              color: #fff;
              font-size: 18px;
              cursor: pointer;
            }
          }
          .info {
            font-size: 20px;
            color: #2c2c2c;
            line-height: 36px;
          }
        }
      }
    }
  }
  .secongbox {
    width: 80%;
    height: 26%;
  }
  #second {
    width: 470px;
    border: none;
    flex-direction: column;
    > li {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;
      line-height: 0.2rem;
      .chose {
        // width: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // span {
        //   width: 100%;
        //   line-height: none;
        //   background-color: none;
        // }
      }
      .second {
        width: 400px;
        border: none;
      }
    }
  }
}
.qrcodebox {
  min-height: 850/96in !important;
  overflow: hidden;
  p {
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
    font-size: 20px;
  }
}
.qrcodes {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 92px;
  /deep/img {
    width: 450px;
  }
}
/deep/.el-dialog__header {
  text-align: center;
  color: #2c2c2c;
  font-weight: bold;
}
/deep/.el-dialog {
  width: 676px !important;
  height: 690px;

  .dia-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 68px;
      border: 1px solid #cdcdcd;
      width: 500px;
      height: 52px;
      li {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: 16px;
        color: #2c2c2c;
        line-height: 52px;
        cursor: pointer;
        b {
          font-weight: 400;
          height: 51px;
        }
        .li_b {
          color: #1c5eff;
          font-weight: bold;
          border-bottom: 3px solid #1c5eff;
        }
      }
      span {
        display: inline-block;
        width: 1px;
        height: 40px;
        background: #c5c5c5;
      }
    }
    .dia-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0 0 0;
      .third {
        width: 544px;
        margin-top: 50px;
        ul {
          width: 100%;
          margin: 0;
        }
        .t_title {
          width: 196px;
          height: 33px;
          display: flex;
          justify-content: flex-start;
          display: flex;
          justify-content: space-around;
          border: 2px solid #dddfe2;
          border-bottom: none;
          border-radius: 5px 5px 0 0;
          > li {
            width: 47px;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            b {
              height: 100%;
              width: 47px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .b_active {
              height: 35px;
              display: flex;
              z-index: 0;
              align-items: center;
              justify-content: center;
              border-bottom: 2px solid #fff;
              border-top: 2px solid #2b6bfb;
            }
          }

          span {
            display: inline-block;
            width: 2px;
            height: 100%;
            background-color: #dddfe2;
          }
        }
        .t_info {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          > li {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 50%;
            height: 100%;
            border: 1px solid #dddfe2;
          }
        }
        // table {
        //   border-collapse: collapse;
        //   border: 1px solid #dddfe2;
        // }
        // tr {
        //   display: flex;
        // }
        // td {
        //   width: 271px;
        //   height: 60px;
        //   display: flex;
        //   justify-content: space-around;
        //   align-items: center;
        //   border-collapse: collapse;
        //   border: 1px solid #dddfe2;
        // }
      }
    }
  }
}
/deep/.el-dialog__body {
  padding: 20px 30px 0 !important;
}
</style>